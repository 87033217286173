











































































import { userModule } from "@/store/modules/user";
import { auth as firebaseAuth } from "./services/firebase";
import { computed, defineComponent, reactive, ref } from "@vue/composition-api";
import { configureScope } from "@sentry/vue";
import { gucciRouter, provideRouter, useRouter } from "./router";
import { identifyUser } from "./services/analytics";
import { loadDynamicModules } from "./store/dynamicModules";
import { Notification, provideNotifier } from "./providers/notifier";
import { uiModule } from "@/store/modules/ui";
import { useModule } from "./store/hooks";
import AppBar from "@/components/app/layout/AppBar.vue";
import authModule from "./store/modules/auth";
import AuthorizedRoot from "./components/app/AuthorizedRoot.vue";
import AutomaticOnboarding from "@/views/onboarding/AutomaticOnboarding.vue";
import ContactMe from "@/views/onboarding/ContactMe.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/onboarding/Register.vue";
import store from "./store";
import stripe from "@/services/stripe";
import { RoutesConfig } from "./router/Routes";

export default defineComponent({
  name: "App",
  components: {
    AppBar,
    AuthorizedRoot,
    AutomaticOnboarding,
    ContactMe,
    Login,
    Register,
  },
  setup() {
    const isLoading = ref(true);
    const { state, mutations } = useModule(authModule);
    provideRouter(gucciRouter);
    provideNotifier((notification) => notify(notification));
    const { currentRoute } = useRouter();

    const vm = reactive({
      notification: {
        title: "",
        message: "",
        type: "success",
      } as Notification,
      alertVisible: false
    });

    function notify(notification: Notification) {
      vm.notification = notification;
      vm.alertVisible = true;
    }

    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        identifyUser(user.uid);
        configureScope((scope) => {
          scope.setUser({
            id: user.uid,
          });
        });

        Promise.all([loadDynamicModules(store, user.uid), stripe.setup()])
          .then(() => {
            mutations.authorize(user.uid);
            isLoading.value = false;
            return uiModule.actions.fetchUnreadNewsMessageCount();
          })
          .catch(() => {
            throw new Error(
              "Failed to load dynamic modules despite being authorized",
            );
          });
      }
      else {
        mutations.deAuthorize();
        isLoading.value = false;
      }
    });

    const simulationInfo = computed(() => {
      return state.simulate ? `${userModule?.state.profile.firstName} ${userModule?.state.profile.lastName} ${userModule?.state.userId}` : "";
    });

    const { refresh, navigate } = useRouter();
    function stopSimulation() {
      navigate(RoutesConfig.SELLER_DASHBOARD.path);
      refresh();
    }

    return {
      currentRoute,
      isAuthorized: computed(() => state.isAuthorized),
      isLoading,
      RoutesConfig,
      simulationInfo,
      state,
      stopSimulation,
      vm
    };
  },
});
